<template>
  <div class="tour-utility-container">
    <div class="utility-section">
      <h5>Add event</h5>
      <p>Add an additional event to the tour</p>
      <StandardSolidButton
        title="Add event"
        :fluid="false"
        :desktopFluidity="true"
        @click="$router.push({ name: 'tour-overview-add-event' })"
      />
    </div>
    <div class="utility-section">
      <h5>Tour export</h5>
      <p>Export results from all events to a .xlsx file.</p>
      <StandardSolidButton
        title="Export results"
        :fluid="false"
        :locked="exportLoading"
        :desktopFluidity="true"
        @click="downloadResults($route.params.tourId)"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
export default {
  name: "TourUtilities",
  components: { StandardSolidButton },
  data() {
    return {
      exportLoading: false,
    };
  },
  computed: {},
  methods: {
    async downloadResults(tourId) {
      const currentDate = moment().format("D-M-YY");
      try {
        this.exportLoading = true;
        const tourName = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          {
            tour(tourId:"${this.$router.currentRoute.params.tourId}"){
              name
            }
          }
          `,
          },
        });

        const fileName = `${tourName.data.data.tour.name}-${currentDate}.xlsx`;
        const file = await this.$axios({
          method: "get",
          timeout: 30000,
          url:
            process.env.VUE_APP_ROOT_EXPORT + `tours/${tourId}/export-events`,
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([file.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        this.exportLoading = false;
      } catch (err) {
        console.log(err);
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.tour-utility-container {
  padding: 0 12px;
  width: 100%;
}
.utility-section {
  padding: 20px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 3px $sleet;
  margin-bottom: 24px;
  width: 100%;

  h5 {
    margin-bottom: 8px;
  }

  button {
    margin-top: 18px;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .utility-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 40px 30px;
    button {
      margin-top: 0;
    }

    h5 {
      width: 100%;
    }
  }
}
</style>
